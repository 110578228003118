import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import pw3 from "../../content/assets/documentation-images/pw3.webp"
import connection_scheme from "../../content/assets/documentation-images/connection_scheme.jpg"
import octocoupler from "../../content/assets/documentation-images/octocoupler.webp"
import var1 from "../../content/assets/documentation-images/var1.jpg"
import var2 from "../../content/assets/documentation-images/var2.jpg"
import var3 from "../../content/assets/documentation-images/var3.jpg"
import ss49_sensor from "../../content/assets/documentation-images/ss49_sensor.webp"
import ss49ecw_sensor from "../../content/assets/documentation-images/49ecw_sensor.jpg"
import ss49e_sensor from "../../content/assets/documentation-images/ss49e_sensor.webp"
import SoftwareUpdates from '../components/SoftwareUpdates'

import SEO from "../components/seo"
import docs_page from '../../content/assets/page_images/docs_page.webp'

const Documentation = (props) => {
    const data = useStaticQuery(graphql`
      {
        site {
          siteMetadata {
            title
            slogan
          }
        }
      }
    `)
    const siteTitle = data.site.siteMetadata.title
    const siteSlogan = data.site.siteMetadata.slogan
    const frontmatter = {
      slug: props.location.pathname.replace(/[^\w\d-]/g, '')
    };
    

    return (
      <Layout  title={siteTitle} slogan={siteSlogan}>
        <SEO
          title="Official Documentation For PW"
          description="Have you purchased your first Power Watcher and not sure what to do with it? This page will help you discover all the features of this device, how to set it up, and you'll be up and running in no time!"
          image={docs_page}
          frontmatter={frontmatter}
        />
        <main>
          <h1 className="blog-heading">Documentation</h1>
          <p>This is the official documentation for the Power Watcher v.3, written by Yuriy Logvin.</p>
          <p>You can download user manual using the link below:</p>
          <div className="download-wrapper">
            <a className="buttonDownload" href="/Power_Watcher_v3_Manual.pdf" download>Download PDF</a>
          </div>
          <h2 className="doc-heading">Device Description</h2>
          <p>Power Watcher device is used for measurement of a current, voltage, speed, temperature and a number of parameters calculated as functions thereof, e.g. a power, energy consumption rate, number of charge/discharge cycles, mileage, etc.</p>
          <p><em>Application area:</em> electric bicycles, scooters, electro mobiles, electric cars, or other electric transportation means.</p>
          <p>Data is indicated via an external device which can be an Android (v4.0 or newer) based smartphone or a tablet capable of supporting Bluetooth2.0.</p>
          <p>The device consists of a "hardware" and "software" parts.</p>
          <p>The <em>"Hardware"</em> part is a board with microcontroller and embedded firmware, which are connected to the Bluetooth-adapter and sensors measuring the current speed, temperature, voltage.</p>
          <p>The <em>"Software"</em> part is a program, which runs on the Android operating system.</p>
          <p>"Software" and "hardware" interact with each other via Bluetooth.</p>
          <table className="rwd-table two-columns">
            <tbody> 
              <tr>
                <th>Parameter</th>
                <th>Value</th>
              </tr>
              <tr>
                <td data-th="Parameter">Min voltage, V</td>
                <td data-th="Value">24 / 10</td>
              </tr>
              <tr>
                <td data-th="Parameter">Max voltage, V</td>
                <td data-th="Value">200 / 600 (for external voltage sensor)</td>
              </tr>
              <tr>
                <td data-th="Parameter">Power supply, V</td>
                <td data-th="Value">200 / 36</td>
              </tr>
              <tr>
                <td data-th="Parameter">Min operational temperature, ᴼС</td>
                <td data-th="Value">-20</td>
              </tr>
              <tr>
                <td data-th="Parameter">Max operational temperature, ᴼС</td>
                <td data-th="Value">80</td>
              </tr>
              <tr>
                <td data-th="Parameter">Current</td>
                <td data-th="Value">Calculated by the sensor</td>
              </tr>
              <tr>
                <td data-th="Parameter">Temperature, ᴼС</td>
                <td data-th="Value">-55...+125</td>
              </tr>
              <tr>
                <td data-th="Parameter">Operational distance, m</td>
                <td data-th="Value">3...20</td>
              </tr>
            </tbody>
          </table>
        <p>The device measures the following parameters:</p>
        <ul>
          <li>Current</li>
          <li>Voltage</li>
          <li>Speed</li>
          <li>Temperature (2 sensors)</li>
        </ul>
        <p>The device calculates:</p>
        <ul>
          <li>Ampere-hour spent</li>
          <li>Total Ampere-hour spent (during operation of the device)</li>
          <li>Watt-hour spent (Watt-hours when charging is not counted, recuperated - counted)</li>
          <li>Ampere-hour recuperation</li>
          <li>Ampere-hour charged</li>
          <li>Watt-hour recuperation</li>
          <li>Recuperation percentage</li>
          <li>Charge level %</li>
          <li>Charge/discharge cycle count</li>
          <li>Distance</li>
          <li>Distance rest</li>
          <li>Total distance</li>
          <li>Min voltage</li>
          <li>Max voltage</li>
          <li>Мax speed</li>
          <li>Average speed</li>
          <li>Time on the road</li>
        </ul>
        <p>Customizable parameters:</p>
        <ul>
          <li>Wheel length</li>
          <li>Number of speed sensor rotation poles</li>
          <li>Current sensor sensitivity</li>
          <li>Battery capacity, AH</li>
          <li>Charged voltage</li>
          <li>Velocity gauge max, Km/H</li>
          <li>Power gauge, min, Watt</li>
          <li>Power gauge, max, Watt</li>
          <li>Current zero temperature correction</li>
          <li>Temp sensor type</li>
          <li>Display power in Kw</li>
        </ul>
        <p>Commands:</p>
        <ul>
          <li>Start the new cycle</li>
          <li>Reset current offset to zero</li>
          <li>Reset all stored data</li>
        </ul>
        <h2 className="doc-heading">Power Watcher Software</h2>
        <p>Power Watcher software has 3 screens – “Display”, “All parameters” and “Settings”. You can switch between the screens by swiping left or right.</p>
        <h2 className="doc-heading">Screen Descriptions</h2>
        <h3>Main Screen</h3>
        <p>The “Main screen” solely indicates the selected parameters. It has several “zones”:</p>
        <ul>
          <li>Center. This screen includes the "battery", charge percentage and energy spent. This is a static zone and display parameters in it can't be changed.</li>
          <li>Upper-left. Configurable display area, as well as all subsequent ones.</li>
          <li>Upper-right. </li>
          <li>Center. </li>
          <li>Bottom-left. </li>
          <li>Bottom-center. </li>
          <li>Bottom-right. </li>
        </ul>
        <h3>All parameters</h3>
        <p>This screen allows selecting the parameter set to be indicated on the “Display” screen. To specify the parameter position, you need to tap on the parameter, then in the context menu select its place on the display. This screen is scrollable.</p>
        <h3>Settings</h3>
        <p>On the “Settings” screen you can specify the wheel length, shunt resistance etc.</p>
        <h2 className="doc-heading">Additional Software Features</h2>
        <p><strong>Main screen parameter selection</strong> ¬— tap and hold the parameter that you want to change for at least 2 seconds.  Then select the parameter that you want to display on the main screen.</p>
        <p><strong>Charge cycle reset</strong> — press and hold the battery icon for at least 2 seconds.</p>
        <h2 className="doc-heading">Device Variants</h2>
        <h3>By Power Source</h3>
        <p>Power Watcher can be supplied without a high-voltage converter, for use in low-voltage (up to 36 V) devices or to be powered from the onboard 12V network (in this case, the voltage is measured using an external RS485 device).</p>
        <p>The device in this case is called <strong>PowerWatcher3LV</strong> (low voltage).</p>
        <p>The photo below shows the device with and without a converter. The pinout of the VD4 and VD5 diodes determines where the 12 volt cascade will be powered from. This means that by purchasing a board with a soldered high-voltage cascade, moving the diode from VD4 to VD5, we get a device with a power range of up to 36 volts instead of 200.</p>
        <img className="doc-image" src={pw3} alt="Power Watcher with and without converter"></img>
        <h2 className="doc-heading">Connecting the Sensors</h2>
        <p>The device must be connected to the current sensor, battery, thermo resistor and the speed sensor. </p>
        <img className="doc-image" src={connection_scheme} alt="Connection scheme"></img>
        <ul>
          <li><strong>NTCM-3.3K thermo resistors</strong>. NTC1 and NTC2 on the scheme.</li>
          <li><strong>Current sensor</strong>. As a current sensor, an analog Hall Effect sensor is usually used. It is connected as shown on the scheme. Digital sensor can be used instead of analog.</li>
          <li><strong>Speed sensor</strong> — it’s a simple reed switch. Or any sensor with the open collector transistor on output (such as a digital Hall sensor). Various ways of connecting the speed sensor will be described below.</li>
          <li><strong>Digital sensors</strong>. Digital current and voltage sensors as well as the screen are connected to the RS485 bus.</li>
        </ul>
        <h3>The Speed Sensor</h3>
        <p>An optocoupler is installed at the input of the PW speed signal. This allows us to quite flexibly connect the speed sensor.</p>
        <img className="doc-image-small" src={octocoupler} alt="Octocoupler"></img>
        <p>There are several connection variants.</p>
        <ol>
          <li>On the electric bike to one of the hall sensors of the motor-wheel.
            <img className="doc-image" src={var1} alt="First connection variant"></img>
          </li>
          <li>Separate sensor which is used only by the Power Watcher.
            <img className="doc-image" src={var2} alt="Second connection variant"></img>
          </li>
          <li>Reed switch.
            <img className="doc-image" src={var3} alt="Third connection variant"></img>
          </li>
        </ol>
        <h3>The Current Sensor</h3>
        <p>Current sensors of two types can be used: analog or digital. There are 3 kinds of them: built-in analog, external analog, external digital.</p>
        <p>Built-in is soldered into the board of the device and allows to measure currents up to 140A. To measure high currents, an external sensor should be used.</p>
        <h4 className="doc-heading-four">PW-SS49E-CORE Sensor</h4>
        <p>This sensor is based on the analog Hall sensor SS49E. The sensor measures the current that flows through the ring. It is shown on the photo below.</p>
        <img className="doc-image" src={ss49_sensor} alt="Hall sensor SS49E"/>
        <p>Where “<strong>Bat</strong>-” — negative terminal of the battery.</p>
        <p>And “<strong>Load</strong>” — load.</p>
        <p>Wire pinout is the same as for “<strong>PW-ACS75X</strong>” sensor.</p>
        <blockquote className="important-blockquote">
          <p><strong>IMPORTANT!</strong> For this sensor in the settings you need to specify:</p>
          <ul>
            <li><strong>Coefficient of temperature zero current correction</strong>— the value of the correction factor is indicated on the sensor.</li>
            <li><strong>Current sensor sensitivity</strong> — depends on the width of the cut in the ferrite ring. The cut value is indicated on the inner surface of the ring in the process of manufacturing.</li>
          </ul>
        </blockquote>
        <table className="rwd-table three-columns center-aligned">
          <tbody>
            <tr>
              <th>Gap, mm</th>
              <th>Value, mkV/A</th>
              <th>Measured currents, A</th>
            </tr>
            <tr>
              <td data-th="Gap, mm">2,5</td>
              <td data-th="Value, mkV/A">9000</td>
              <td data-th="Measured currents, A">±170</td>
            </tr>
            <tr>
              <td data-th="Gap, mm">3</td>
              <td data-th="Value, mkV/A">7000</td>
              <td data-th="Measured currents, A">±220</td>
            </tr>
            <tr>
              <td data-th="Gap, mm">4,3</td>
              <td data-th="Value, mkV/A">5000</td>
              <td data-th="Measured currents, A">±320</td>
            </tr>
            <tr>
              <td data-th="Gap, mm">5</td>
              <td data-th="Value, mkV/A">4000</td>
              <td data-th="Measured currents, A">±400</td>
            </tr>
            <tr>
              <td data-th="Gap, mm">6</td>
              <td data-th="Value, mkV/A">3500</td>
              <td data-th="Measured currents, A">±450</td>
            </tr>
            <tr>
              <td data-th="Gap, mm">6,7</td>
              <td data-th="Value, mkV/A">3000</td>
              <td data-th="Measured currents, A">±500</td>
            </tr>
          </tbody>
        </table>
        <p><strong>Explanations</strong>: Each current sensor contains a thermal sensor. When a current is set to zero, the voltage at the output of the sensor, which corresponds to zero current and temperature, is stored. During current measurements, zero drift correction is performed depending on temperature.</p>
        <h4 className="doc-heading-four">PW-SS49E-CW Sensor</h4>
        <p>This sensor is almost identical to “PW-SS49E-CORE”, and differs from the latter only in that the wires are soldered to the sensor board.</p>
        <img className="doc-image" src={ss49ecw_sensor} alt="Sensor SS49ECW"/>
        <h4 className="doc-heading-four">PW-SS49E Sensor</h4>
        <p>This sensor is almost identical to “PW-SS49E-CORE”. And it differs from the latter by the absence of a ferrite magnetic field concentrator ring.</p>
        <p>For the sensor to work correctly, the magnetic field lines must cross the plane of the sensor body along a normal (perpendicular). See the photo below.</p>
        <img className="doc-image" src={ss49e_sensor} alt="Sensor SS49E"/>
        <p>Where “<strong>Bat</strong>-” — negative terminal of the battery.</p>
        <p>And “<strong>Load</strong>” — load.</p>
        <p>See the <strong>PW-SS49E-CORE</strong> sensor description for details.</p>
        <h3>Temperature sensors</h3>
        <p>The device can be used with 2 temperature sensors.</p>
        <p>As a first sensor, 3 variants are possible:</p>
        <ul>
          <li>N110K003.30 (NTC 3.3k)</li>
          <li>KTY83</li>
          <li>KTY84</li>
        </ul>
        <p>As a second sensor — only N110K003.30.</p>
        <h2 className="doc-heading">Setting Up The Device</h2>
        <ol>
          <li>Power on the device.</li>
          <li>Pair Android device with the Power Watcher. The pin code is “0000”.</li>
          <li>On the "Settings" page (you can switch between pages with a swipe) set the following parameters:
            <ul>
              <li><strong>Wheel Length</strong>.</li>
              <li><strong>Current sensor sensitivity</strong>. The calculation of this value is described in the "Current sensor" chapter. </li>
              <li><strong>Battery Capacity</strong>.</li>
              <li><strong>Charged Voltage</strong>. The value must be less than voltage at the end of the charge by 0.5-1V.</li>
              <li><strong>Reset current offset to zero</strong>.</li>
            </ul>
          </li>
          <li>Return to the previous pages is done with the "Return" button.</li>
        </ol>
        <h2 className="doc-heading">Operation Algorithm</h2>
        <p>To calculate the correct energy values, device must be active both during charge and during discharge. </p>
        <p>During the charge process, if the battery output voltage is higher than parameter «Charged Voltage», this means that the charging process has finished. </p>
        <p>When this condition occurs, the values of distance, spent energy, average and maximum values of speed, current and voltage are cleared. </p>
        <p>Power level is set to 100%.</p>
        <p>The battery charge level is calculated based on the total capacity in Ampere-hours and Ampere-hours spent.</p>
        <SoftwareUpdates></SoftwareUpdates>
        </main>
      </Layout>
    )
}

export default Documentation

