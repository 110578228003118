import React from 'react'

const SoftwareUpdates = () => {
  return(
    <>
      <h2 className="doc-heading">Software Update</h2>
      <p>The software can be updated from the USB-flash drive.</p>
      <p>USB-flash must have only one volume and formatted in FAT32.</p>
      <p>The sequence of steps to update the firmware:</p>
      <ul>
        <li>Copy the firmware file to the root of the USB-flash drive. (Example: "pwu_2_02.em1")</li>
        <li>Power off the device.</li>
        <li>Connect USB-flash instead of the Bluetooth-adapter.</li>
        <li>Turn on the power.</li>
        <li>The blue LED should start to blink - it means that the process of firmware update is in progress.</li>
        <li>Upon completion of the firmware update, the two LED (blue and red) are lit.</li>
        <li>Disconnect USB-flash. LEDs should go out.</li>
        <li>Connect Bluetooth-adapter.</li>
      </ul>
      <p>On error, red and blue LEDs will be flashing alternately: once the red one, N-times the blue one. Where N - the error code.</p>
      <p>Error codes:</p>
      <ul>
        <li><strong>1</strong> — waiting for the USB-device connection</li>
        <li><strong>2</strong> — usb-flash has no new firmware</li>
        <li><strong>3</strong> — usb-flash has no firmware</li>
        <li><strong>5</strong> — can’t read the firmware file</li>
        <li><strong>6</strong> — firmware file header read error</li>
        <li><strong>7</strong> — incorrect firmware file signature</li>
      </ul>
    </>
  )
}

export default SoftwareUpdates